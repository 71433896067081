<template>
	<div class="orderList">
		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">退库日期:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="datetimerange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
							:default-time="['00:00:00', '23:59:59']" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">仓库:</div>
					<div class="item-input">
						<el-select v-model="searchForm.warehouse_id" placeholder="请选择">
							<el-option v-for="item in warehouseOptions" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">退回供应商:</div>
					<div class="item-input">
						<el-select v-model="searchForm.erp_supplier_id" filterable placeholder="请选择供应商"
							style="width: 100%;">
							<el-option v-for="item in supplierList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">商品名称:</div>
					<div class="item-input">
						<el-input v-model="searchForm.key" placeholder="请输入商品名称" />
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning"
						@click="loading = true, page = 1, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">搜索</el-button>
					<el-button
						@click="loading = true, page = 1, pageNum = 10, searchForm = {}, getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="info" icon="el-icon-refresh-left" @click="loading = true, getList()"></el-button>
			</div>
			<el-table :data="tableData" style="width: 100%" border :header-cell-style="{
                background: '#f5f7fa',
                fontWeight: 'bold',
                color: '#303133'
            }">
				<el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
				<el-table-column label="商品主图" align="center">
					<template slot-scope="scope">
						<el-image style="width: 50px; height: 50px" :src="scope.row.goods_thumb"
							:preview-src-list="[scope.row.goods_pic]">
						</el-image>
					</template>
				</el-table-column>
				<el-table-column prop="goods_intro" label="商品简介" align="center"></el-table-column>
				<el-table-column prop="attribute" label="自定义属性" align="center"></el-table-column>
				<el-table-column prop="price" label="平均价" align="center"></el-table-column>
				<el-table-column prop="count" label="数量" align="center"></el-table-column>
				<el-table-column prop="amount" label="小计" align="center">
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dateVal: [],
				searchForm: {
					begin: '',
					end: '',
				},
				// 仓库
				warehouseOptions: [],
				supplierList: [], //供应商
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
			}
		},
		mounted() {
			// 仓库
			this.getWarehouseList()
			// 供应商
			this.getSupplierList()
			// 时间
			this.dateVal = [this.getCurrentDate() + ' ' + '00:00:00', this.getCurrentDate() + ' ' + '23:59:59']
			this.searchForm.begin = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]
			// 列表
			this.getList()
		},
		methods: {
			getList() {
				this.$http.post(`/erp/v1/tkd/summary`, {
					page: this.page,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			// 仓库
			getWarehouseList() {
				this.$http.post(`erp/v1/warehouse/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.warehouseOptions = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 供应商
			getSupplierList() {
				this.$http.post(`/erp/v1/supplier/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.supplierList = data
					} else {
						this.$message.error(msg);
					}
				});
			},
		},
	}
</script>